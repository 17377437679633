// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-store-tsx": () => import("./../../../src/pages/app-store.tsx" /* webpackChunkName: "component---src-pages-app-store-tsx" */),
  "component---src-pages-commercial-transactions-tsx": () => import("./../../../src/pages/commercial-transactions.tsx" /* webpackChunkName: "component---src-pages-commercial-transactions-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact/success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-croccha-tsx": () => import("./../../../src/pages/croccha.tsx" /* webpackChunkName: "component---src-pages-croccha-tsx" */),
  "component---src-pages-google-play-tsx": () => import("./../../../src/pages/google-play.tsx" /* webpackChunkName: "component---src-pages-google-play-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-support-tsx": () => import("./../../../src/pages/it-support.tsx" /* webpackChunkName: "component---src-pages-it-support-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-qrcode-tsx": () => import("./../../../src/pages/qrcode.tsx" /* webpackChunkName: "component---src-pages-qrcode-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../../../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */),
  "component---src-pages-sdgs-handmade-tsx": () => import("./../../../src/pages/sdgs-handmade.tsx" /* webpackChunkName: "component---src-pages-sdgs-handmade-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

